import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../../Routes';
import { trackEvent } from '../../../services/analytics';
import Dots from './Dots';
import AlterationsPrice from './AlterationsPrice';
import ClothesSlider from './ClothesSlider';
import { mobileThresholdPixels, margins, colors, ContainerBlock, Title2,
  Button } from './StyledComponents';

const Title1 = styled(Title2.withComponent('h1'))``;

const ButtonContainer = styled.div`
  margin: ${margins.l} 0px 0px 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    margin: ${margins.m} 0px 0px 0px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  border: 2px solid ${colors.navy};
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    border-width: 0px;
  }
`;

const Text = styled.p`
  text-align: center;
`;

const TextUnderline = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

class Pricings extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedClothSlug: 'pant',
      showAllPricings: false,
    };
  }

  render() {
    return (
      <ContainerBlock>
        <Dots big />
        <Title1 big>Nos tarifs</Title1>
        <SubContainer>
          <ClothesSlider
            selectedClothSlug={this.state.selectedClothSlug}
            changeSelectedCloth={slug => this.setState({ selectedClothSlug: slug })}
          />
          <AlterationsPrice selectedClothSlug={this.state.selectedClothSlug} />
        </SubContainer>

        <Text>
          {'Seules les retouches les plus courantes sont affichées ci-dessus, '}
          <TextUnderline onClick={this.props.openAllPricings}>
            cliquez ici pour voir tous nos tarifs
          </TextUnderline>
        </Text>

        <Link
          to={routesMap.OrderPage.url}
          onClick={() => trackEvent('click', 'commande_block-pourquoi-tilli')}
        >
          <ButtonContainer>
            <Button>Commander</Button>
          </ButtonContainer>
        </Link>
      </ContainerBlock>
    );
  }
}

Pricings.propTypes = {
  openAllPricings: PropTypes.func,
};

Pricings.defaultProps = {
  openAllPricings() {},
};

export default Pricings;
