import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../../Routes';
import { trackEvent } from '../../../services/analytics';
import isMobile from '../../../services/isMobile';
import Dots from './Dots';
import { mobileThresholdPixels, margins, fontSizes, ContainerBlock, Title2, EmphText,
  Button } from './StyledComponents';

const Span1 = styled.span`font-size: ${isMobile() ? fontSizes.s : fontSizes.l};`;
const Span2 = styled.span`font-size: ${isMobile() ? '11px' : fontSizes.m};`;
const Indented = styled.span`margin-left: ${isMobile() ? '0px' : '32px'};`;
const A = styled.a`color: inherit;`;

const ButtonContainer = styled.div`
  margin: 0px 0px ${margins.xl} 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    margin: 0px 0px ${margins.l} 0px;
  }
`;

const paragraphs = [
  {
    id: 1,
    title: 'Pourquoi proposez-vous deux tarifs ?',
    text: <Span1>
      Il y a une tarification pour les vêtements basiques / simples et
      une autre pour les vêtements habillés / complexes.
    </Span1>,
    smallText: <Span2>
      Ex : Ourlet simple concerne les jeans, les chinos, etc. <br />{!isMobile() && <br />}
      <Indented>Ourlet tailleur concerne les costumes, les pantalons en soie, etc.</Indented>
    </Span2>,
  },
  {
    id: 2,
    title: 'Comment payer ma prestation ?',
    text: <Span1>
      {'Paiement par carte sécurisé, en ligne sur nos plateformes tilli : Site & '}
      <A
        href={'https://itunes.apple.com/us/app/tilli/id1280578121'}
        onClick={() => trackEvent('click', 'appStore_block-pricingsFAQ')}
      >
        application mobile
      </A>
    </Span1>,
  },
  {
    id: 3,
    title: 'Puis-je commander mon couturier sans détailler mes retouches ?',
    text: <Span1>
      Bien sûr, vous pouvez commander sans détailler vos retouches.
      Votre couturier se chargera de faire votre devis en rendez-vous avec vous.
    </Span1>,
  },
  {
    id: 4,
    title: 'Suis-je débité directement après avoir commandé sur le site ?',
    text: <Span1>
      Non, lors de votre réservation en ligne, nous vérifions uniquement que votre carte
      est valide pour assurer le déplacement de nos Tillistes à votre domicile. <br /><br />
      Vous serez débité du montant de vos retouches après votre rendez-vous avec votre couturier.
      Le montant qui vous sera prélevé sera celui du devis réalisé avec votre couturier pendant votre rendez-vous
      et validé par vos soins.
    </Span1>,
  },
  {
    id: 5,
    title: 'Si mon vêtement est constitué d’une doublure, quel impact sur les prix ?',
    text: <Span1>
      {`Pour toute retouche, si votre vêtement contient une doublure à l'intérieur, cela entraîne un travail
      supplémentaire pour le couturier, donc un ajustement des prix.`}
    </Span1>,
    smallText: <Span2>{'Pour en savoir plus, faites votre '}
      <Link
        key={routesMap.OrderPage.url} style={{ color: 'inherit' }}
        to={routesMap.OrderPage.url}
        onClick={() => trackEvent('click', 'devis-detaille_block-pricingsFAQ')}
      >
        devis détaillé.
      </Link>
    </Span2>,
  },
  {
    id: 6,
    title: 'Puis-je vous confier mes vêtements précieux comme ma robe de mariée ?',
    text: <Span1>
      L’un des plaisirs de ce métier est de pouvoir travailler de jolies matières et
      des montages complexes. Vous ravirez nos couturiers avec vos belles pièces ! <br /><br />
      Tous vêtements confiés à Tilli sont assurés par AXA.
    </Span1>,
    smallText: <Span2>{'Pour en savoir plus sur '}
      <Link
        key={routesMap.Insurance.url} style={{ color: 'inherit' }}
        to={routesMap.Insurance.url}
        onClick={() => trackEvent('click', 'assurance_block-pricingsFAQ')}
      >
        notre assurance axa.
      </Link>
    </Span2>,
  },
];

const ParagraphContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
  }
`;

const PositionedEmphText = styled(EmphText)`margin-top: ${margins.m};`;

const ClosingEmphText = styled(EmphText)`
  margin-top: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const SubContainer = styled.div`font-family: Roboto;`;

const Closing = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    margin: ${margins.m} 0px;
  }
`;

const Title3 = styled.h3`
  font-weight: bold;
  font-size: ${fontSizes.xl};
  letter-spacing: 0.6px;
  margin: ${margins.xl} 0px ${margins.m} 0px;
  ${props => props.noMarginTop && 'margin-top: 0px'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    margin: ${margins.l} 0px ${margins.s} 0px;
    ${props => props.noMarginTop && 'margin-top: 0px'};
  }
`;

const PricingsFAQ = () => (
  <ContainerBlock>
    <Dots big />
    <Title2 big>FAQ</Title2>
    <PositionedEmphText center>Paiement & tarifs</PositionedEmphText>
    <SubContainer>
      {paragraphs.map(paragraph =>
        <ParagraphContainer key={paragraph.id}>
          <Title3>{paragraph.title}</Title3>
          {paragraph.text}
          {paragraph.smallText && <span><br /><br />{paragraph.smallText}</span>}
        </ParagraphContainer>,
      )}
    </SubContainer>
    <Closing>
      <Dots big right={!isMobile()} />
      <Title3 noMarginTop>Plus de questions ?</Title3>
      <ClosingEmphText>{'Rendez-vous sur notre page dédiée '}
        <Link
          key={routesMap.FAQPage.url} style={{ color: 'inherit' }}
          to={routesMap.FAQPage.url}
          onClick={() => trackEvent('click', '$faq_block-pricingsFAQ')}
        >
          FAQ
        </Link>
      </ClosingEmphText>
    </Closing>
    <Link
      to={routesMap.OrderPage.url}
      onClick={() => trackEvent('click', 'commande_block-pourquoi-tilli')}
    >
      <ButtonContainer>
        <Button>Commander</Button>
      </ButtonContainer>
    </Link>
  </ContainerBlock>
);

export default PricingsFAQ;
